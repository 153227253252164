import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function UserReminderModal({
  hasUnsavedChanges,
  setHasUnsavedChanges,
  saveAllChanges,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // handle tab switch and page reloads
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        // prevent navigation
        event.preventDefault();
        // required for Chrome compatibility
        event.returnValue = '';
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && hasUnsavedChanges) {
        setOpen(true);
      }
    };

    // when component mounts, this code will run
    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      // this code will run when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [hasUnsavedChanges]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2" gutterBottom>
          Unsaved Changes
        </Typography>
        <Typography sx={{ mb: 2 }}>
          You have unsaved changes. Would you like to save them?
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mr: 1 }}
          onClick={() => {
            setOpen(false);
            setHasUnsavedChanges(false);
            saveAllChanges();
          }}
        >
          Save Changes
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Continue Editing
        </Button>
      </Box>
    </Modal>
  );
}
