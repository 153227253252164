import React, { useState, useEffect } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // show or hide the button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // scroll back to the top when the button is clicked
  const scrollToTop = () => {
    setTooltipOpen(false); // close the tooltip immediately
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // smooth scrolling animation
    });
  };

  return (
    <Tooltip
      title="Back to Top"
      open={tooltipOpen}
      onOpen={() => {
        setTooltipOpen(true);
      }}
      onClose={() => {
        setTooltipOpen(false);
      }}
    >
      <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          backgroundColor: 'primary.main',
          color: 'white',
          boxShadow: 3,
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
          display: isVisible ? 'inline-flex' : 'none', // hide when not visible
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ScrollToTopButton;
