import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { List, ListItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import nutrition from '../../../assets/images/nutrition.jpeg';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 1,
  borderRadius: 2,
};

const modalSideBanner = {
  width: '250px',
  height: 'auto',
  marginRight: '20px',
  borderTopLeftRadius: '20px',
  borderBottomLeftRadius: '20px',
};

const modalContainer = {
  display: 'flex',
  flexDirection: 'row',
};

const modalText = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
};

const checkIconSizing = {
  width: '17px',
  marginRight: 2,
};

const listItemText = {
  mb: 3,
  display: 'flex',
  alignItems: 'center',
};

export default function AutoShowModal({ milliseconds = 10000 }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true); // open the modal after 10 seconds
    }, milliseconds); // 10 seconds

    return () => clearTimeout(timer); // cleanup function to prevent memory leaks
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Box sx={modalContainer}>
          <Box>
            <img alt="familyimage" src={nutrition} style={modalSideBanner} />
          </Box>
          <Box sx={modalText}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Join Parentswarm
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
              Unlock these features and tons of amazing others.
            </Typography>
            <List>
              <Typography sx={listItemText}>
                <CheckIcon color="primary" sx={checkIconSizing} />
                Save your Favorite Resources
              </Typography>

              <Typography sx={listItemText}>
                <CheckIcon color="primary" sx={checkIconSizing} />
                Access our exclusive Digital Bonus Kit
              </Typography>

              <Typography sx={listItemText}>
                <CheckIcon color="primary" sx={checkIconSizing} />
                Match with Resources 24/7/365
              </Typography>

              <Typography sx={listItemText}>
                <CheckIcon color="primary" sx={checkIconSizing} />
                Receive Discounts & Special Offers
              </Typography>
            </List>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                sx={{ marginRight: 2 }}
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                href="https://app.nessle.com/login/parents"
              >
                Create Account
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
